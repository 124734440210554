import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';
import { StyledLinearProgress } from './style';
import Grid from '@mui/material/Grid';

interface LoadingComponentProps {
  text?: string;
}

const LoadingComponent = ({
  text = 'Loading...',
}: LoadingComponentProps): JSX.Element => {
  return (
    <Grid
      container
      flexDirection="row"
      justifyContent="center"
      width="100%"
      marginTop="1rem"
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '16px 36px',
          gap: '4px',
          width: '27rem',

          backgroundColor: '#E7F4F7',
          border: '1px solid #37BEDD',
          boxShadow: '2px 2px 4px rgba(37, 77, 115, 0.2)',
          borderRadius: '8px',
        }}
      >
        <Typography>{text}</Typography>
        <StyledLinearProgress />
      </Box>
    </Grid>
  );
};

export default LoadingComponent;
