import { numericFormatter, patternFormatter } from 'react-number-format';

export const PARSED_BOOLEAN_TUPPLE_LABELS = ['Yes', 'No'] as const;

export const PARSED_BOOLEAN_TUPPLE_VALUES = ['true', 'false'] as const;

export const CARD_TYPES_LABELS = ['Physical Card', 'Virtual Card'] as const;

export const CARD_TYPES_VALUES = ['PHYSICAL', 'VIRTUAL'] as const;

export const WIDGET_TYPE = {
  ACTIVATE_CARD: 'activate_card',
  SET_PIN: 'set_pin',
} as const;

export const ACTIONS = {
  FREEZE: 'freezeCard',
  UN_FREEZE: 'unfreezeCard',
  TERMINATE: 'terminate',
  SET_PIN: 'setPIN',
  ACTIVATE: 'activate',
  SET_MONTH_LIMIT: 'setMonthLimit',
  SET_NICKNAME: 'setNickname',
};

export const ACTION_LIST = [
  {
    id: ACTIONS.FREEZE,
    value: ACTIONS.FREEZE,
    label: 'Freeze Card',
    disabled: false,
  },
  {
    id: ACTIONS.UN_FREEZE,
    value: ACTIONS.UN_FREEZE,
    label: 'Un-Freeze Card',
    disabled: false,
  },
  {
    id: ACTIONS.TERMINATE,
    value: ACTIONS.TERMINATE,
    label: 'Terminate Card',
    disabled: false,
  },
  {
    id: ACTIONS.SET_PIN,
    value: ACTIONS.SET_PIN,
    label: 'Set PIN',
    disabled: false,
  },
  {
    id: ACTIONS.ACTIVATE,
    value: ACTIONS.ACTIVATE,
    label: 'Activate Card',
    disabled: false,
  },
];

export enum StorageKeys {
  Token = 'user.token',
  TncProgress = 'tnc.progress',
  UserTrails = 'ajs_user_traits',
}

export enum EPaymentMethod {
  ACH = 'ACH',
  WIRE = 'Wire',
}

export const SYNCTERA_ROLES = {
  managingPerson: {
    label: 'Controller',
    id: 'MANAGING_PERSON_OF',
  },
  owner: {
    label: 'Owner',
    id: 'BENEFICIAL_OWNER_OF',
  },
} as const;

export const SyncteraRoleList = [
  SYNCTERA_ROLES.managingPerson.id,
  SYNCTERA_ROLES.owner.id,
] as const;

export const LEGAL_DOCUMENTS = {
  termsPlatformAndMerchant: 'https://financial.paybotic.com/commercial/tcs/',
  cardHolderAgreement:
    'https://financial.paybotic.com/commercial/merchant-cardholder-agreement/',
  esignConsentDisclosure:
    'https://payboticfinancial.com/e-sign-consent-disclosure/',
  patrioticAct: 'https://payboticfinancial.com/usa-patriot-act-disclosure/',
  achAuthorization:
    'https://financial.paybotic.com/commercial/ach-authorization-agreement-disclosure/',
  privacyCompliance:
    'https://storage.googleapis.com/paybotic-banking-fe-develop/Privacy%20Policy.pdf',
  terms:
    'https://storage.googleapis.com/paybotic-banking-fe-develop/Terms%20and%20Conditions',
  privacyNotice: 'https://payboticfinancial.com/privacy-notice/',
  regCCDisclosure:
    'https://financial.paybotic.com/commercial/reg-cc-disclosure/',
};

export const CASH_PICKUP_LINK = 'https://cashservices.empyreallogistics.com/';

export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_TO_REQUEST_TRANSACTIONS = 'yyyy-MM-dd';

export const FIXED_COUNTRY = 'US';

export const PAYBOTIC_INFO = {
  email: 'support@payboticfinancial.com',
  phone: '8334207300',
};

const baseFormatter = (value: string, format: string): string =>
  patternFormatter(value, { format });

export const EIN_FORMAT = '##-#######';
export const ENCRYPTED_EIN_FORMAT = '**-***####';

export const EIN_FORMATTER = (value: string, isEncrypted = false): string =>
  baseFormatter(value, isEncrypted ? ENCRYPTED_EIN_FORMAT : EIN_FORMAT);

export const SSN_FORMAT = '###-##-####';
export const ENCRYPTED_SSN_FORMAT = '***-**-####';

export const SIN_FORMAT = '###-###-###';
export const ENCRYPTED_SIN_FORMAT = '***-***-###';

export const MONEY_FORMATTER = (value: number): string =>
  numericFormatter(value.toString(), {
    thousandSeparator: true,
    decimalScale: 2,
  });

export const SSN_FORMATTER = (value: string, isEncrypted = false): string =>
  baseFormatter(value, isEncrypted ? ENCRYPTED_SSN_FORMAT : SSN_FORMAT);

export const SIN_FORMATTER = (value: string, isEncrypted = false): string =>
  baseFormatter(value, isEncrypted ? ENCRYPTED_SIN_FORMAT : SIN_FORMAT);

export const US_PHONE_FORMAT = '(###) ###-####';
export const US_PHONE_FORMAT_WITH_PREFIX = '+# (###) ###-####';

export const PHONE_FORMATTER = (value: string): string =>
  baseFormatter(value, US_PHONE_FORMAT);

export const PHONE_FORMATTER_WITH_PREFIX = (value: string): string =>
  baseFormatter(value, US_PHONE_FORMAT_WITH_PREFIX);

export const FORMAT_FROM_SYNCTERA_CURRENCY_VALUE = (value: number): number =>
  value / 100;

export const getDigitsInString = (str: string): string =>
  str.replace(/\D*/g, '');

export const FORMAT_TO_SYNCTERA_CURRENCY_VALUE = (value: string): number => {
  const amount = value.replace(/,/g, '');
  const cents = Math.round(parseFloat(amount) * 100);
  return cents;
};

export const PAYBOTIC_CONTACT_PHONE = {
  formatted: PHONE_FORMATTER(PAYBOTIC_INFO.phone),
  formattedWithPrefix: PHONE_FORMATTER_WITH_PREFIX(`1${PAYBOTIC_INFO.phone}`),
};

export const CUSTOM_FILTER_MENU_HEIGHT = {
  PaperProps: {
    style: {
      maxHeight: 345,
    },
  },
};

export const TABLE_TYPE = {
  PENDING: 'Pending',
  POSTED: 'Posted',
  UPCOMING: 'Upcoming',
  DECLINED: 'Declined',
};

export const TRANSACTION_LIMIT = 25;
export const OTP_LENGTH = 6;

export const APP_EVENTS = {
  LOGOUT: 'logout_app',
};
