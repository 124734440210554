import type {
  BusinessApplicationProgressIdentifier,
  BusinessDetails,
} from '@/types/business';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';

const useSetStepPending = () => {
  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();

  const handleSetStepPending = async ({
    currentSection,
    business,
    refetchBusinessProgress,
  }: {
    currentSection: BusinessApplicationProgressIdentifier;
    business: BusinessDetails | undefined;
    refetchBusinessProgress: any;
  }) => {
    const businessId = business?.id;
    if (businessId == null) return;

    const progressSections =
      business?.businessApplicationProgress?.sections !== undefined
        ? business?.businessApplicationProgress.sections.filter(
            (section) => section.id !== currentSection,
          )
        : [];

    await updateBusinessApplicationProgress.mutateAsync(
      {
        businessId,
        sections: [
          ...progressSections,
          {
            id: currentSection,
            status: 'Pending',
          },
        ],
        pagesCompleted:
          business?.businessApplicationProgress?.pagesCompleted ?? undefined,
      },
      {
        onSuccess() {
          refetchBusinessProgress().catch(console.error);
        },
      },
    );
  };

  return { handleSetStepPending };
};

export default useSetStepPending;
