import { useState, useMemo, useEffect } from 'react';
import _mapValues from 'lodash/mapValues';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import type { SelectChangeEvent } from '@mui/material';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import InputDropdown from '@/components/Forms/InputDropdown';
import type { OptionValue } from '@/components/Forms/InputDropdown/types';
import type { SystemUserAuthResponse } from '@/types/user';
import type { IEmailNotification } from '@/types/account';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import { EEmailNotificationType } from '@/types/account';
import useUpdateEmailNotifications from '@/api/hooks/useUpdateEmailNotifications';
import useGetEmailNotifications from '@/api/hooks/useGetEmailNotifications';
import useGetFacilityBankAccount from '@/api/hooks/useGetFacilityBankAccount';
import ToastMessage from '@/components/UI/ToastMessage';
import { useRoles } from '@/store/useRoles';
import { SUBSCRIBE_ON, checkedDefault, NotificationTabEnum } from './utils';
import TabNotificationContent from './TabNotificationContent';
import NotificationItem from './NotificationItem';
import { convertAmountToSyncterraBalance } from '@/utils/format';

interface IModalEmailNotifications {
  open: boolean;
  systemUser?: SystemUserAuthResponse;
  onClose: () => void;
}

const LOW_THRESHOLD_ACCOUNT_BALANCE = 5000;
const HIGHT_INCOMING_TRANSACTION_AMOUNT_THRESHOLD = 0;
const HIGHT_OUTGOING_TRANSACTION_AMOUNT_THRESHOLD = 100;

const findMetadata = (
  emailNotifications: IEmailNotification[] | undefined,
  type: EEmailNotificationType,
  facility: string,
) => {
  return emailNotifications?.find(
    (item) => item.type === type && item.facilityId === facility,
  )?.metadata;
};

export default function ModalEmailNotifications({
  open,
  systemUser,
  onClose,
}: IModalEmailNotifications) {
  const [isSaving, setIsSaving] = useState(false);
  const [facility, setFacility] = useState<string>('');
  const [checked, setChecked] = useState({ ...checkedDefault });
  const { isAdmin, isOperating, isCardholder, isAccounting } = useRoles(
    (role) => ({
      isAdmin: role.isAdmin,
      isOperating: role.isOperating,
      isCardholder: role.isCardholder,
      isAccounting: role.isAccounting,
    }),
  );
  const [selectedTab, setSelectedTab] = useState(
    NotificationTabEnum.BALANCE_ALERTS,
  );
  const [notificationTab, setNotificationTab] = useState({
    [NotificationTabEnum.BALANCE_ALERTS]: {
      label: 'Balance Alerts',
      isShow: isAdmin || isOperating || isAccounting,
      isSelected: false,
    },
    [NotificationTabEnum.ACCOUNT_ACTIVITY]: {
      label: 'Account Activity',
      isShow: isOperating || isAccounting,
      isSelected: false,
    },
    [NotificationTabEnum.CARD_ACTIVITY]: {
      label: 'Card Activity',
      isShow: isAdmin || isOperating || isCardholder,
      isSelected: false,
    },
    [NotificationTabEnum.TEAMS]: {
      label: 'Teams',
      isShow: isAdmin,
      isSelected: false,
    },
  });
  const [lowThresholdAccountBalance, setLowThresholdAccountBalance] =
    useState(0);
  const [
    highThresholdIncomingTransactionAmount,
    setHighThresholdIncomingTransactionAmount,
  ] = useState(0);
  const [
    highThresholdOutgoingTransactionAmount,
    setHighThresholdOutgoingTransactionAmount,
  ] = useState(0);

  const handleTabChange = (tab: NotificationTabEnum) => {
    setSelectedTab(tab);
    const newNotificationTab = { ...notificationTab };
    Object.keys(newNotificationTab).forEach((key) => {
      newNotificationTab[key].isSelected = key === tab;
    });
    setNotificationTab(newNotificationTab);
  };

  const { mutate: updateEmailNotifications } = useUpdateEmailNotifications();

  const {
    data: emailNotifications,
    refetch,
    isPending,
  } = useGetEmailNotifications(facility, {
    enabled: !!facility,
  });

  const { data: accountData, isPending: isAccountDataPending } =
    useGetFacilityBankAccount(facility ?? '', {
      enabled: facility != null,
    });

  const handleToggle = (key: string) => () => {
    const isClickUnsubscribeAll = key === SUBSCRIBE_ON;

    if (isClickUnsubscribeAll) {
      const updatedAllCheck = _mapValues(
        checkedDefault,
        () => !checked[SUBSCRIBE_ON],
      );
      setChecked(updatedAllCheck);

      return;
    }

    const newChecked = {
      ...checked,
      [key]: !checked[key],
      [SUBSCRIBE_ON]: true,
    };

    setChecked({
      ...newChecked,
    });
  };

  const listFacilities = useMemo(() => {
    return (
      systemUser?.facilities
        ?.filter(
          (facility) =>
            facility.applicationStatus === ApplicationStatusEnum.APPROVED,
        )
        ?.map((facility) => ({
          label: facility.facilityName,
          id: facility.id,
          value: facility.id,
        })) || []
    );
  }, [systemUser?.facilities]);

  const onSetFacility = (
    facility: SelectChangeEvent<OptionValue<string>>,
  ): void => {
    setFacility(facility?.target?.value as string);
  };

  const onSave = () => {
    const getMetadata = (key: string) => {
      if (key === EEmailNotificationType.MAIN_ACCOUNT_BALANCE) {
        return {
          lowThreshold: lowThresholdAccountBalance,
          notified: false,
        };
      }

      if (key === EEmailNotificationType.CREDIT_TRANSACTION) {
        return {
          highThreshold: highThresholdIncomingTransactionAmount,
        };
      }

      if (key === EEmailNotificationType.DEBIT_TRANSACTION) {
        return {
          highThreshold: highThresholdOutgoingTransactionAmount,
        };
      }

      return null;
    };

    const payload = Object.keys(checked)
      .filter((key) => key !== SUBSCRIBE_ON)
      .map((key) => ({
        facilityId: facility,
        type: key,
        enabled: checked[key],
        id: emailNotifications?.find(
          (item) => item.type === key && item.facilityId === facility,
        )?.id,
        metadata: getMetadata(key),
      }));

    setIsSaving(true);

    updateEmailNotifications(payload, {
      onSuccess: async () => {
        setIsSaving(false);
        ToastMessage.success('Email notifications saved successfully');
        await refetch();
        onClose();
      },
      onError: () => {
        setIsSaving(false);
        ToastMessage.error('Failed to save email notifications');
      },
    });
  };

  useEffect(() => {
    if (listFacilities.length && !facility) {
      setFacility(listFacilities?.[0]?.id as string);
    }
  }, [listFacilities, facility]);

  useEffect(() => {
    if (emailNotifications?.length) {
      const isHasEnabled = emailNotifications.some((item) => item.enabled);

      setChecked((prev) => {
        const newChecked = { ...prev, [SUBSCRIBE_ON]: isHasEnabled };

        emailNotifications.forEach((item) => {
          newChecked[item.type] = item.enabled;
        });

        return newChecked;
      });
    } else {
      setChecked({ ...checkedDefault });
    }

    const metadataMainAccountBalance = findMetadata(
      emailNotifications,
      EEmailNotificationType.MAIN_ACCOUNT_BALANCE,
      facility,
    );
    const metadataCreditTransaction = findMetadata(
      emailNotifications,
      EEmailNotificationType.CREDIT_TRANSACTION,
      facility,
    );
    const metadataDebitTransaction = findMetadata(
      emailNotifications,
      EEmailNotificationType.DEBIT_TRANSACTION,
      facility,
    );

    setLowThresholdAccountBalance(
      metadataMainAccountBalance?.lowThreshold || LOW_THRESHOLD_ACCOUNT_BALANCE,
    );

    setHighThresholdIncomingTransactionAmount(
      metadataCreditTransaction?.highThreshold ||
        HIGHT_INCOMING_TRANSACTION_AMOUNT_THRESHOLD,
    );

    setHighThresholdOutgoingTransactionAmount(
      metadataDebitTransaction?.highThreshold ||
        HIGHT_OUTGOING_TRANSACTION_AMOUNT_THRESHOLD,
    );
  }, [emailNotifications, facility]);

  const renderCheckInfoAlerts = () => {
    if (isPending) {
      return <CircularProgress color="inherit" size={16} sx={{ ml: '12px' }} />;
    }

    return (
      <Switch
        checked={checked[SUBSCRIBE_ON]}
        disableRipple
        onClick={handleToggle(SUBSCRIBE_ON)}
      />
    );
  };

  const renderBalanceAlerts = () => {
    const getTitle = () => {
      if (accountData?.accountNumberMasked && !isAccountDataPending) {
        const accountNumber = accountData?.accountNumberMasked;

        return `Checking ${accountNumber?.slice(-8)}`;
      }

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography fontSize={'14px'}>Checking</Typography>
          <CircularProgress color="inherit" size={16} sx={{ ml: '12px' }} />
        </Box>
      );
    };

    const handleChangeValue = (e) => {
      const value = e.target.value;
      const formattedValue = convertAmountToSyncterraBalance(value) || 0;
      const storedValue = formattedValue / 100;

      setLowThresholdAccountBalance(storedValue);
    };

    return (
      <TabNotificationContent
        value={selectedTab}
        index={NotificationTabEnum.BALANCE_ALERTS}
      >
        <Typography fontWeight={500} gutterBottom fontSize={'16px'}>
          Balance alerts
        </Typography>
        <Typography color="#9a9a9d" gutterBottom fontSize={'14px'}>
          Set a custom threshold for your account and get an email alert when
          the balance falls below it.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <NotificationItem
            title={getTitle()}
            checked={checked[EEmailNotificationType.MAIN_ACCOUNT_BALANCE]}
            handleToggle={handleToggle(
              EEmailNotificationType.MAIN_ACCOUNT_BALANCE,
            )}
            customValue={{
              content: 'When balance falls below:',
              value: lowThresholdAccountBalance,
              handleChangeValue,
            }}
          />
        </Box>
      </TabNotificationContent>
    );
  };

  const renderAccountActivity = () => {
    const handleChangeValueCreditTransaction = (e) => {
      const value = e.target.value;
      const formattedValue = convertAmountToSyncterraBalance(value) || 0;
      const storedValue = formattedValue / 100;

      setHighThresholdIncomingTransactionAmount(storedValue);
    };

    const handleChangeValueDebitTransaction = (e) => {
      const value = e.target.value;
      const formattedValue = convertAmountToSyncterraBalance(value) || 0;
      const storedValue = formattedValue / 100;

      setHighThresholdOutgoingTransactionAmount(storedValue);
    };

    const handleToggleCashDepositChangeOrder = () => {
      setChecked((prevChecked) => {
        const newChecked = {
          ...prevChecked,
          [EEmailNotificationType.CASH_DEPOSIT]:
            !prevChecked[EEmailNotificationType.CASH_DEPOSIT],
          [EEmailNotificationType.CHANGE_ORDER]:
            !prevChecked[EEmailNotificationType.CHANGE_ORDER],
          [SUBSCRIBE_ON]: true,
        };
        return newChecked;
      });
    };

    return (
      <TabNotificationContent
        value={selectedTab}
        index={NotificationTabEnum.ACCOUNT_ACTIVITY}
      >
        <Typography fontWeight={500} gutterBottom fontSize={'16px'}>
          Account activity
        </Typography>
        <Typography color="#9a9a9d" gutterBottom fontSize={'14px'}>
          Monitor large transaction, troubleshoot payment issues, and more.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <NotificationItem
            title="Incoming transaction exceeds a custom dollar amount"
            checked={checked[EEmailNotificationType.CREDIT_TRANSACTION]}
            handleToggle={handleToggle(
              EEmailNotificationType.CREDIT_TRANSACTION,
            )}
            customValue={{
              content: 'When an incoming transaction exceeds:',
              value: highThresholdIncomingTransactionAmount,
              handleChangeValue: handleChangeValueCreditTransaction,
            }}
          />
          <NotificationItem
            title="Outgoing transaction exceeds a custom dollar amount"
            checked={checked[EEmailNotificationType.DEBIT_TRANSACTION]}
            handleToggle={handleToggle(
              EEmailNotificationType.DEBIT_TRANSACTION,
            )}
            customValue={{
              content: 'When an outgoing transaction exceeds:',
              value: highThresholdOutgoingTransactionAmount,
              handleChangeValue: handleChangeValueDebitTransaction,
            }}
          />
          <NotificationItem
            title="Cash Deposit / Change Order"
            checked={checked[EEmailNotificationType.CASH_DEPOSIT]}
            handleToggle={handleToggleCashDepositChangeOrder}
          />
          <NotificationItem
            title="Scheduled Payments"
            checked={checked[EEmailNotificationType.SCHEDULE_PAYMENT]}
            handleToggle={handleToggle(EEmailNotificationType.SCHEDULE_PAYMENT)}
          />
          <NotificationItem
            title="Failed Payments"
            checked={checked[EEmailNotificationType.SCHEDULE_PAYMENT]}
            handleToggle={handleToggle(EEmailNotificationType.SCHEDULE_PAYMENT)}
          />
        </Box>
      </TabNotificationContent>
    );
  };

  const renderCardActivity = () => {
    return (
      <TabNotificationContent
        value={selectedTab}
        index={NotificationTabEnum.CARD_ACTIVITY}
      >
        <Typography fontWeight={500} gutterBottom fontSize={'16px'}>
          Account activity
        </Typography>
        <Typography color="#9a9a9d" gutterBottom fontSize={'14px'}>
          Track approved and declined card transactions, and get notified when a
          debit card is added or frozen.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <NotificationItem
            title="Approved Card transaction"
            checked={checked[EEmailNotificationType.POSTED_CARD_TRANSACTION]}
            handleToggle={handleToggle(
              EEmailNotificationType.POSTED_CARD_TRANSACTION,
            )}
          />
          <NotificationItem
            title="Declined Card transaction"
            checked={checked[EEmailNotificationType.DECLINED_CARD_TRANSACTION]}
            handleToggle={handleToggle(
              EEmailNotificationType.DECLINED_CARD_TRANSACTION,
            )}
          />
          <NotificationItem
            title="Debit Card is added"
            checked={checked[EEmailNotificationType.DEBIT_CARD_ADDED]}
            handleToggle={handleToggle(EEmailNotificationType.DEBIT_CARD_ADDED)}
          />
          <NotificationItem
            title="Debit Card is frozen"
            checked={checked[EEmailNotificationType.DEBIT_CARD_FROZEN]}
            handleToggle={handleToggle(
              EEmailNotificationType.DEBIT_CARD_FROZEN,
            )}
          />
          <NotificationItem
            title="Debit Card is unfrozen."
            checked={checked[EEmailNotificationType.DEBIT_CARD_UNFREEZE]}
            handleToggle={handleToggle(
              EEmailNotificationType.DEBIT_CARD_UNFREEZE,
            )}
          />
          <NotificationItem
            title="Debit Card is terminated."
            checked={checked[EEmailNotificationType.DEBIT_CARD_TERMINATED]}
            handleToggle={handleToggle(
              EEmailNotificationType.DEBIT_CARD_TERMINATED,
            )}
          />
        </Box>
      </TabNotificationContent>
    );
  };

  const renderTeams = () => {
    return (
      <TabNotificationContent
        value={selectedTab}
        index={NotificationTabEnum.TEAMS}
      >
        <Typography fontWeight={500} gutterBottom fontSize={'16px'}>
          Teams
        </Typography>
        <Typography color="#9a9a9d" gutterBottom fontSize={'14px'}>
          Manage users.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <NotificationItem
            title="New team member is added"
            checked={checked[EEmailNotificationType.NEW_SYSTEM_USER]}
            handleToggle={handleToggle(EEmailNotificationType.NEW_SYSTEM_USER)}
          />
        </Box>
      </TabNotificationContent>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 800,
            bgcolor: 'background.paper',
            borderRadius: '8px',
            boxShadow: 24,
            p: '24px',
            outline: 'none',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '380px',
            }}
          >
            <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
              <Typography id="modal-modal-title" component="h1">
                Subscribe Email Notifications
              </Typography>
              {renderCheckInfoAlerts()}
              <Box
                sx={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}
              >
                <InputDropdown
                  name="facility"
                  sx={{ marginLeft: 'auto' }}
                  value={facility}
                  options={listFacilities}
                  placeholder="Select"
                  onChange={onSetFacility}
                  noNoneOption
                />
              </Box>
            </Box>

            <Box sx={{ mt: 1, display: 'flex', flexDirection: 'column' }}>
              <Box
                sx={{ display: 'flex', flexDirection: 'row', minHeight: 522 }}
              >
                <Tabs
                  orientation="vertical"
                  sx={{ borderRight: 1, borderColor: 'divider' }}
                  value={selectedTab}
                  onChange={(_, value) => setSelectedTab(value)}
                >
                  {Object.keys(notificationTab).map((key) => {
                    const tab = notificationTab[key];
                    return tab.isShow ? (
                      <Tab
                        sx={{
                          width: '180px',
                          fontSize: '16px',
                          alignItems: 'start',
                        }}
                        key={key}
                        value={key}
                        label={tab.label}
                        onClick={() =>
                          handleTabChange(key as NotificationTabEnum)
                        }
                      />
                    ) : null;
                  })}
                </Tabs>
                {renderBalanceAlerts()}
                {renderAccountActivity()}
                {renderCardActivity()}
                {renderTeams()}
              </Box>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                mt: 'auto',
                gap: '8px',
              }}
            >
              <Button
                onClick={onClose}
                disabled={isSaving}
                sx={{
                  minWidth: '110px',
                }}
              >
                <span>Cancel</span>
              </Button>
              <Button
                onClick={onSave}
                disabled={isSaving}
                variant="contained"
                sx={{
                  minWidth: '110px',
                }}
              >
                {isSaving && (
                  <CircularProgress
                    color="inherit"
                    size={14}
                    sx={{ mr: '2px' }}
                  />
                )}
                <span>Save</span>
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
