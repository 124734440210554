/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import IdleTimer from '@/utils/idleTimer';
import { useAuth0 } from '@auth0/auth0-react';
import Router, { PublicRouter } from '@components/Router';
import { useSentry } from '@/utils/useSentry';
import { useLocation } from 'react-router-dom';
import { MEASUREMENT_ID } from '@/constants/env';
import Debugger from '@/components/Debugger';
import { getLocationInfo } from '@/constants/page-mapping-ga';
import { GA } from '@/utils/ga';
import useLogout from '@/utils/hooks/useAuth/useLogout';
import { APP_EVENTS, StorageKeys } from '@/constants/misc';

ReactGA.initialize(MEASUREMENT_ID);

function App(): JSX.Element {
  useSentry();
  const location = useLocation();
  const isPublic = location.pathname.startsWith('/public');

  const idleTimerRef = useRef<any>({});
  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const { logout } = useLogout();

  const isUserAuthed = !isLoading && isAuthenticated;

  useEffect(() => {
    if (['/', '/public'].includes(location.pathname)) return;

    const { page, title } = getLocationInfo(location.pathname) as {
      title: string;
      page: string;
    };

    GA.send({ page, title, hitType: 'pageview' });
  }, [location.pathname]);

  useEffect(() => {
    if (!isAuthenticated) return;

    idleTimerRef.current = new IdleTimer({
      timeout: 60 * 10,

      onTimeout: () => {
        logout();
      },
    });

    return () => {
      idleTimerRef.current?.cleanUp();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isUserAuthed) return;

    const getUserToken = async (): Promise<void> => {
      const userToken = await getAccessTokenSilently();

      if (!userToken) {
        logout();
        return;
      }

      localStorage.setItem(StorageKeys.Token, userToken);
    };

    getUserToken().catch(logout);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuthed]);

  useEffect(() => {
    const handleLogout = () => {
      logout();
    };
    document.addEventListener(APP_EVENTS.LOGOUT, handleLogout);

    return () => {
      document.removeEventListener(APP_EVENTS.LOGOUT, handleLogout);
    };
  }, [logout]);

  return (
    <>
      {isPublic ? <PublicRouter /> : <Router />}
      <Debugger />
    </>
  );
}

export default App;
