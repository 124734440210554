import { zodResolver } from '@hookform/resolvers/zod';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTheme, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import ControlBar from '@/components/UI/ControlBar';

import { CSS_VARS } from '@/constants/css-vars';
import { Router } from '@/constants/router';

import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useGetBusinessFacilityInformation from '@/api/hooks/useGetBusinessFacilityInformation';
import useUpdateFacilityInformation from '@/api/hooks/useUpdateFacilityInformation';

import { useFacilitySectionData } from '@/pages/Facilities/hooks/useFacilitySectionData';

import { BANKING_ACTIVITY_SECTION_ID } from '../data';

import {
  BANKING_ACTIVITY_PAGE_ID,
  formSchema,
  getNormalizedData,
  setBankingActivityValues,
} from './data';

import InputField from './InputField';

import useGetApplicationFeedbacks from '@/api/hooks/useGetApplicationFeedbacks';
import useGetBusiness from '@/api/hooks/useGetBusiness';
import useGetBusinessApplicationProgress from '@/api/hooks/useGetBusinessApplicationProgress';
import useGetBusinessValidation from '@/api/hooks/useGetBusinessValidation';
import { ApplicationStatusEnum } from '@/api/hooks/useGetBusinessValidation/type';
import useUpdateBusinessApplicationProgress from '@/api/hooks/useUpdateBusinessApplicationProgress';
import type { FormSchema } from './types';
import { useOnboardingSections } from '@/utils/hooks/useOnboardingSections';
import { useOnboardingProgress } from '@/store/useOnboardingProgress';
import { useDiscardModal } from '@/utils/hooks/useDiscardModal';
import ToastMessage from '@/components/UI/ToastMessage';
import useSetStepPending from '@/utils/hooks/useSetStepPending';

function BankingActivityDetail(): JSX.Element {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  const currentTier = searchParams.get('tier');
  const isTierTwo = currentTier === '2';
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({});

  const authenticatedUser = useAuthenticatedUser();
  const { isChangedTier } = useOnboardingProgress();
  const { checkSectionCompleted } = useOnboardingSections();
  const { id: businessId } = authenticatedUser.data?.businesses?.[0] ?? {};
  const { data: business } = useGetBusiness({ businessId });

  const hasFinishedOnboard = business?.finishedOnboard;

  const updateFacilityInformation = useUpdateFacilityInformation();

  const { facilityId, facilityCompleted } = useFacilitySectionData({
    title: 'Tell us more about your banking activity.',
    description: 'Please complete the following to the best of your ability',
    pageNumber: 16,
  });

  const isFacilityCompleted = !business?.finishedOnboard
    ? checkSectionCompleted('facilities')
    : facilityCompleted;
  const shouldHandleNormal = !isFacilityCompleted || isChangedTier;

  const updateBusinessApplicationProgress =
    useUpdateBusinessApplicationProgress();
  const { handleSetStepPending } = useSetStepPending();

  const {
    data: businessApplicationProgress,
    refetch: refetchBusinessProgress,
  } = useGetBusinessApplicationProgress(businessId ?? '', {
    enabled: businessId != null,
  });

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    getValues,
    watch,
    clearErrors,
  } = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
  });

  const { handleCancel, DiscardPopup } = useDiscardModal({
    oldData: initialValues,
    newData: watch(),
    onDiscard: () => {
      if (isTierTwo) {
        navigate(Router.Onboarding.Facilities.LocationReview.ActivityTierTwo);
      } else {
        if (hasFinishedOnboard) {
          navigate(Router.Onboarding.Facilities.BankingActivity.Review);
        } else {
          navigate(Router.Onboarding.Facilities.LocationReview.ActivityTierOne);
        }
      }
    },
  });

  const { data: businessFacilityInformation } =
    useGetBusinessFacilityInformation(
      businessId ?? '',
      facilityId ?? '',
      BANKING_ACTIVITY_SECTION_ID,
      BANKING_ACTIVITY_PAGE_ID,
      {
        enabled: businessId != null && facilityId != null,
        onSuccess(businessFacilityInformation) {
          setBankingActivityValues(businessFacilityInformation, setValue);
          setInitialValues(getValues());
        },
      },
    );

  const isLoading = updateFacilityInformation.isPending;

  const { data: businessValidation } = useGetBusinessValidation(
    businessId ?? '',
    {
      enabled: businessId != null,
    },
  );

  const { data: applicationFeedbacks } = useGetApplicationFeedbacks(
    facilityId ?? '',
    {
      enabled:
        facilityId != null &&
        businessValidation?.status ===
          ApplicationStatusEnum.REVIEW_INFO_REQUESTED,
    },
  );

  const isApplicationResubmission =
    businessValidation?.status === ApplicationStatusEnum.REVIEW_INFO_REQUESTED;

  const onSubmitHandler = (data, goNext?: boolean): void => {
    if (businessId == null || facilityId == null) return;

    const sectionData = getNormalizedData(data);

    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          await updateBusinessApplicationProgress.mutateAsync(
            {
              businessId,
              pagesCompleted:
                businessFacilityInformation?.id !== '' ||
                authenticatedUser?.data?.businesses?.[0]?.finishedOnboard
                  ? undefined
                  : (businessApplicationProgress?.pagesCompleted ?? 0) + 1,
            },
            {
              onSuccess() {
                refetchBusinessProgress().catch(console.error);
                if (!!shouldHandleNormal || goNext) {
                  if (isTierTwo) {
                    navigate(
                      `${Router.Onboarding.Facilities.LocationReview.ActivityTierTwo}?tier=2`,
                    );
                  } else {
                    navigate(
                      `${Router.Onboarding.Facilities.BankingActivity.PrimaryBusiness}`,
                    );
                  }
                } else {
                  if (isTierTwo) {
                    navigate(
                      Router.Onboarding.Facilities.LocationReview
                        .ActivityTierTwo,
                    );
                  } else {
                    if (hasFinishedOnboard) {
                      navigate(
                        Router.Onboarding.Facilities.BankingActivity.Review,
                      );
                    } else {
                      navigate(
                        Router.Onboarding.Facilities.LocationReview
                          .ActivityTierOne,
                      );
                    }
                  }
                }
              },
            },
          );
        },
      },
    );
  };

  const onSubmitHandlerDraft = () => {
    if (businessId == null || facilityId == null) return;

    const data = getValues();
    const sectionData = getNormalizedData(data);

    updateFacilityInformation.mutate(
      {
        businessId,
        facilityId,
        section: sectionData,
      },
      {
        async onSuccess() {
          await handleSetStepPending({
            business,
            currentSection: 'facilities',
            refetchBusinessProgress,
          });
          clearErrors();
          ToastMessage.success('Draft saved successfully');
        },
      },
    );
  };

  const renderFooter = () => {
    const goBack = () => {
      if (shouldHandleNormal) {
        const params = isTierTwo ? '?tier=2' : '';

        navigate(
          `${Router.Onboarding.Facilities.BankingActivity.PrimaryPurpose}${params}`,
        );
      } else {
        handleCancel();
      }
    };

    return (
      <ControlBar
        showInfoText={!hasFinishedOnboard && !isApplicationResubmission}
        backButtonProps={{
          text: shouldHandleNormal ? 'Back' : 'Go back to Review',
          onClick: goBack,
        }}
        nextButtonProps={{
          text: shouldHandleNormal ? 'Next' : 'Save',
          onClick: handleSubmit((data) => onSubmitHandler(data)),
          disabled:
            updateBusinessApplicationProgress.isPending ||
            updateFacilityInformation.isPending,
        }}
        saveDraftButtonProps={{
          onClick: onSubmitHandlerDraft,
          show: !isFacilityCompleted,
        }}
        continueButtonProps={{
          show: !shouldHandleNormal && !isTierTwo,
          disabled:
            updateBusinessApplicationProgress.isPending ||
            updateFacilityInformation.isPending,
          onClick: handleSubmit((data) => onSubmitHandler(data, true)),
        }}
        isLoading={isLoading}
      />
    );
  };

  return (
    <>
      <Grid
        alignItems="flex-start"
        container
        flexDirection="column"
        padding={0}
        width={{ sx: '100%', md: '763px' }}
      >
        <Grid
          borderBottom={`0.1rem solid var(${CSS_VARS.Colors.Gray[100]})`}
          container
          gap="10px"
          alignItems="flex-end"
          justifyContent="flex-end"
          padding="10px 0"
          width="763px"
          height="60px"
          display={{ xs: 'none', md: 'flex' }}
        >
          <Typography
            color={`var(${CSS_VARS.Colors.BlackBlue})`}
            fontWeight={700}
            width="259px"
            textAlign="center"
            fontSize="14px"
          >
            Anticipated amount per month
            <span style={{ color: `var(${CSS_VARS.Colors.Error.Dark})` }}>
              *
            </span>
          </Typography>

          <Typography
            color={`var(${CSS_VARS.Colors.BlackBlue})`}
            fontWeight={700}
            textAlign="left"
            width="190px"
            fontSize="14px"
          >
            Estimated number of transactions per month
            <span style={{ color: `var(${CSS_VARS.Colors.Error.Dark})` }}>
              *
            </span>
          </Typography>
        </Grid>

        <Grid flexDirection="column">
          <InputField
            title="Regular Cash Deposits"
            register={register}
            errors={errors}
            amount="regular-cash-amount"
            transactions="regular-cash-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'regular-cash-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'regular-cash-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'regular-cash-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'regular-cash-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Cash Withdrawals"
            register={register}
            errors={errors}
            amount="cash-withdrawals-amount"
            transactions="cash-withdrawals-transactions"
            getValues={getValues}
            greyBackground={!isMobile}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'cash-withdrawals-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Incoming ACH (e.g. paid invoices)"
            register={register}
            errors={errors}
            amount="incoming-ach-amount"
            transactions="incoming-ach-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'incoming-ach-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'incoming-ach-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'incoming-ach-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'incoming-ach-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Outgoing ACH (e.g. Payroll)"
            register={register}
            errors={errors}
            amount="outgoing-ach-amount"
            transactions="outgoing-ach-transactions"
            getValues={getValues}
            greyBackground={!isMobile}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'outgoing-ach-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'outgoing-ach-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'outgoing-ach-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) =>
                      feedback.section === 'outgoing-ach-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Incoming/Outgoing Wires"
            register={register}
            errors={errors}
            amount="wires-amount"
            transactions="wires-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'wires-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="Mobile or Remote Deposit (Check) MRDC"
            register={register}
            errors={errors}
            amount="mrdc-amount"
            transactions="mrdc-transactions"
            getValues={getValues}
            greyBackground={!isMobile}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'mrdc-transactions',
                  ).length
                : false
            }
          />

          <InputField
            title="ATM Usage (Pulling money out of ATM)"
            register={register}
            errors={errors}
            amount="atm-amount"
            transactions="atm-transactions"
            getValues={getValues}
            disabled={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-amount',
                  ).length
                : false
            }
            errorFlag={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-amount',
                  ).length
                : false
            }
            disabledTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-transactions',
                  ).length
                : false
            }
            errorFlagTransaction={
              businessValidation?.status ===
              ApplicationStatusEnum.REVIEW_INFO_REQUESTED
                ? !!(applicationFeedbacks ?? []).filter(
                    (feedback) => feedback.section === 'atm-transactions',
                  ).length
                : false
            }
          />
        </Grid>
      </Grid>

      {renderFooter()}
      <DiscardPopup />
    </>
  );
}

export default BankingActivityDetail;
