import { useState, memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import { ICONS } from '@/constants/icons';
import { CSS_VARS } from '@/constants/css-vars';
import useAuthenticatedUser from '@/api/hooks/useAuthenticateUser';
import useLogout, { useLogoutProfile } from '@/utils/hooks/useAuth/useLogout';
import { ListItemIcon, ListItemText } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { useMultipleProfiles } from '@/store/useMultipleProfiles';
import { useMultipleBusiness } from '@/store/useMultipleBusiness';
import { useRoles } from '@/store/useRoles';
import ModalEmailNotifications from '../ModalEmailNotifications';
import { SystemUserAuthResponse } from '@/types/user';

interface HeaderProps {
  mobileSidebarOpener?: () => void;
  systemUser?: SystemUserAuthResponse;
}

const Header = memo((props: HeaderProps) => {
  const { systemUser, mobileSidebarOpener } = props;
  const userProfile = useMultipleProfiles((state) => state.userProfile);
  const groupProfile = useMultipleProfiles((state) => state.groupProfile);
  const onboardingBusiness = useMultipleBusiness(
    (state) => state.onboardingBusiness,
  );
  const currentBusinessName = userProfile?.businesses?.[0]?.businessName;

  const { isAuthenticated, loginWithRedirect, user } = useAuth0();

  useAuthenticatedUser({
    isEmailVerified: user?.email_verified,
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { logout } = useLogout();
  const { logoutProfile } = useLogoutProfile();

  const [openEmailNotifications, setOpenEmailNotifications] = useState(false);

  const isAllowUpdateEmailNotifications = useRoles(
    (role) => role.isAllowUpdateEmailNotifications,
  );

  const handleAuth = async (): Promise<void> => {
    try {
      if (isAuthenticated) {
        logout();
        return;
      }
      localStorage.removeItem('selected-facility');
      await loginWithRedirect();
    } catch (error) {
      console.error(error);
      Sentry.captureException(error);
    }
  };

  const handleUserAvatarClick = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = (): void => {
    setAnchorEl(null);
  };

  const isShowSwitchBusinesses =
    (groupProfile?.isSuperAdmin && !!onboardingBusiness) ||
    (!!userProfile &&
      (Number(groupProfile?.userProfiles?.length) > 1 ||
        groupProfile?.isSuperAdmin)) ||
    !!userProfile?.hasMultipleProfiles;

  const onTriggerEmailNotifications = (): void => {
    handleUserMenuClose();
    setOpenEmailNotifications(!openEmailNotifications);
  };

  return (
    <>
      <Box
        component="header"
        sx={{
          gridArea: 'header',
          padding: {
            xs: '0.8rem 1.6rem',
            sm: '2.4rem 2rem',
            md: '2.4rem 4.8rem',
          },
          borderBottom: `0.1rem solid var(${CSS_VARS.Colors.Gray[100]})`,
        }}
      >
        <Box
          component="nav"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {mobileSidebarOpener && (
            <IconButton
              onClick={mobileSidebarOpener}
              sx={{
                mr: 1,
                display: {
                  md: 'none',
                },
              }}
            >
              <ICONS.HamburgerIcon width="24" height="24" />
            </IconButton>
          )}
          <Box
            sx={{
              mr: 'auto',
              height: {
                xs: 36,
                md: 49,
              },
              mb: {
                xs: -1,
                md: 0,
              },
              '*': {
                height: '100%',
                width: 'auto',
              },
            }}
          >
            <a href="/">
              <img
                src="https://storage.googleapis.com/paybotic-banking-fe-develop/final_logo.png"
                alt="Paybotic logo"
                width="1312"
                height="352"
              />
            </a>
          </Box>

          {user && (
            <Box>
              <IconButton onClick={handleUserAvatarClick}>
                <Avatar
                  sx={{
                    bgcolor: `var(${CSS_VARS.Colors.BrandBlue[400]})`,
                    width: 32,
                    height: 32,
                    textTransform: 'uppercase',
                  }}
                >
                  {user.email != null ? user.email.charAt(0) : 'P'}
                </Avatar>
              </IconButton>
              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleUserMenuClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 19,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{
                  horizontal: 'right',
                  vertical: 'top',
                }}
                anchorOrigin={{
                  horizontal: 'right',
                  vertical: 'bottom',
                }}
              >
                <MenuItem
                  disabled
                  sx={{
                    '&.Mui-disabled': {
                      opacity: 1,
                    },
                  }}
                >
                  <ListItemText>
                    <Box>
                      <Typography
                        sx={{
                          color: `var(${CSS_VARS.Colors.Gray[500]})`,
                        }}
                      >
                        {user.email}
                      </Typography>

                      {!!groupProfile && !!currentBusinessName && (
                        <Typography
                          variant="caption"
                          sx={{
                            color: (theme) => theme.palette.primary.main,
                            textTransform: 'uppercase',
                          }}
                        >
                          {currentBusinessName}
                        </Typography>
                      )}
                    </Box>
                  </ListItemText>
                </MenuItem>

                <Divider />

                {isAllowUpdateEmailNotifications && (
                  <MenuItem onClick={onTriggerEmailNotifications}>
                    <ListItemIcon>
                      <NotificationImportantOutlinedIcon
                        color="primary"
                        fontSize="small"
                      />
                    </ListItemIcon>
                    <ListItemText>Email Notifications</ListItemText>
                  </MenuItem>
                )}

                {isShowSwitchBusinesses && (
                  <MenuItem onClick={logoutProfile}>
                    <ListItemIcon>
                      <CachedIcon color="primary" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Switch Businesses</ListItemText>
                  </MenuItem>
                )}

                <MenuItem onClick={handleAuth}>
                  <ListItemIcon>
                    <LogoutOutlinedIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Log out</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          )}
          {!isAuthenticated && (
            <Button variant="text" onClick={handleAuth}>
              Log in
            </Button>
          )}
        </Box>
      </Box>
      {isAllowUpdateEmailNotifications && openEmailNotifications && (
        <ModalEmailNotifications
          open={true}
          systemUser={systemUser}
          onClose={onTriggerEmailNotifications}
        />
      )}
    </>
  );
});

if (import.meta.env.DEV) {
  Header.displayName = 'Header';
}

export default Header;
