import type { BusinessAccountDetails } from '@/types/account';

import fetcher from '@/utils/fetcher';

// eslint-disable-next-line @typescript-eslint/promise-function-async
const getFacilityBankAccount = (
  facilityId: string,
): Promise<BusinessAccountDetails> =>
  fetcher<BusinessAccountDetails>({
    endpoint: `/bank-account/facility/${facilityId}`,
  });

export default getFacilityBankAccount;
