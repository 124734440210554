import { useQuery, UseQueryResult } from '@tanstack/react-query';
import getFacilityBankAccount from './getFacilityBankAccount';
import type { BusinessAccountDetails } from '@/types/account';
import type { QueryOptionsRequest } from '@/api/types';

const useGetFacilityBankAccount = (
  facilityId: string,
  queryOptions?: QueryOptionsRequest<BusinessAccountDetails>,
): UseQueryResult<BusinessAccountDetails, Error> => {
  return useQuery<BusinessAccountDetails, Error>({
    queryKey: [`getBankAccount/${facilityId}`],
    queryFn: async () => await getFacilityBankAccount(facilityId),
    ...queryOptions,
  });
};

export default useGetFacilityBankAccount;
