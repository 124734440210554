import { EEmailNotificationType } from '@/types/account';

export const SUBSCRIBE_ON = 'SUBSCRIBE_ON';

export const checkedDefault = {
  [EEmailNotificationType.CASH_DEPOSIT]: true,
  [EEmailNotificationType.CHANGE_ORDER]: true,
  [EEmailNotificationType.CREDIT_TRANSACTION]: true,
  [EEmailNotificationType.DEBIT_TRANSACTION]: true,
  [EEmailNotificationType.SCHEDULE_PAYMENT]: true,
  [EEmailNotificationType.NEW_SYSTEM_USER]: true,
  [EEmailNotificationType.MAIN_ACCOUNT_BALANCE]: true,
  [EEmailNotificationType.DEBIT_CARD_ADDED]: true,
  [EEmailNotificationType.DEBIT_CARD_FROZEN]: true,
  [EEmailNotificationType.DEBIT_CARD_UNFREEZE]: true,
  [EEmailNotificationType.DEBIT_CARD_TERMINATED]: true,
  [EEmailNotificationType.DECLINED_CARD_TRANSACTION]: true,
  [EEmailNotificationType.POSTED_CARD_TRANSACTION]: true,

  [SUBSCRIBE_ON]: true,
};

export enum NotificationTabEnum {
  BALANCE_ALERTS = 'BALANCE_ALERTS',
  ACCOUNT_ACTIVITY = 'ACCOUNT_ACTIVITY',
  CARD_ACTIVITY = 'CARD_ACTIVITY',
  TEAMS = 'TEAMS',
}
