import { FC } from 'react';
import Box from '@mui/material/Box';

interface IProps {
  children: React.ReactNode;
  index: string;
  value: string;
}

const TabNotificationContent: FC<IProps> = ({ index, value, children }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      sx={{
        flex: 1,
      }}
    >
      {value === index && <Box sx={{ px: 3, py: 2 }}>{children}</Box>}
    </Box>
  );
};

export default TabNotificationContent;
