import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import InputMask from '../Forms/InputMask';

interface IProps {
  title: string | JSX.Element;
  checked: boolean;
  handleToggle: () => void;
  customValue?: {
    content: string;
    value: number;
    handleChangeValue: (e: Event | React.SyntheticEvent) => void;
  };
}

const NotificationItem: FC<IProps> = ({
  title,
  checked,
  handleToggle,
  customValue,
}) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          gap: '8px',
          alignItems: 'center',
        }}
      >
        <Switch checked={checked} disableRipple onClick={handleToggle} />
        <Box>{title}</Box>
      </Box>
      {customValue && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'baseline',
            gap: '8px',
            marginLeft: '66px',
            marginTop: '-5px',
          }}
        >
          <Typography fontSize={'14px'}>{customValue.content}</Typography>
          <InputMask
            name="amount"
            label=""
            width={'200px'}
            value={customValue.value}
            onChange={customValue.handleChangeValue}
            disabled={!checked}
            allowNegative={false}
            endAdornment=""
            type="money"
          />
        </Box>
      )}
    </Box>
  );
};

export default NotificationItem;
