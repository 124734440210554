import CircleCheckIcon from '@/assets/icons/check_icon.svg?react';
import CheveronRightIcon from '@/assets/icons/cheveron_right_icon.svg?react';
import OutlineCircleIcon from '@/assets/icons/circle_icon.svg?react';
import ErrorIcon from '@/assets/icons/error_icon.svg?react';
import InformationCircleIcon from '@/assets/icons/information_circle_icon.svg?react';
import InProgressIcon from '@/assets/icons/in_progress_icon.svg?react';
import LoginCards from '@/assets/icons/login_cards.svg?react';
import Logout from '@/assets/icons/logout_icon.svg?react';
import MakeWire from '@/assets/icons/make_wire.svg?react';
import PayboticLogoSimpleIcon from '@/assets/icons/paybotic_logo_simple.svg?react';
import RectangleIcon from '@/assets/icons/rectangle_icon.svg?react';
import VerifyEmail from '@/assets/icons/verify_email.svg?react';
import WriteCheckIcon from '@/assets/icons/write_check_icon.svg?react';
import AlertIcon from '@/assets/icons/alert_icon.svg?react';
import HamburgerIcon from '@/assets/icons/hamburger_icon.svg?react';
import { VscDebugConsole } from 'react-icons/vsc';
import { PiBuildingsFill } from 'react-icons/pi';

import { AiFillEye } from 'react-icons/ai';

import {
  BiCheckCircle,
  BiCheckShield as CheckShield,
  BiInfoCircle,
  BiRadioCircle,
  BiCircle,
  BiSolidFilterAlt,
  BiFilterAlt,
} from 'react-icons/bi';

import { CgInfo as Info } from 'react-icons/cg';
import { SlLink } from 'react-icons/sl';

import {
  FiArrowRightCircle,
  FiDownload as Download,
  FiEdit as EditIcon,
  FiLock as LockIcon,
  FiRefreshCw as RefreshIcon,
  FiXOctagon as OctagonIcon,
} from 'react-icons/fi';

import { FaDownload, FaApplePay, FaGooglePay } from 'react-icons/fa';

import { BsCloudUpload } from 'react-icons/bs';
import {
  HiOutlineArrowNarrowRight as OutlineArrowNarrowRight,
  HiOutlineOfficeBuilding as OutlineOfficeBuilding,
  HiOutlineCalendar as OutlineCalendar,
  HiOutlineCash as OutlineCash,
  HiOutlineCheckCircle as OutlineCheckCircle,
  HiOutlineLibrary as OutlineLibrary,
  HiOutlineUser as OutlineUser,
  HiOutlinePencil as Pencil,
  HiOutlineShieldCheck as ShieldCheck,
  HiOutlineTrash as Trash,
  HiOutlineDownload as DownloadOutlined,
} from 'react-icons/hi';

import { ImCheckboxChecked } from 'react-icons/im';

import CloseMUI from '@mui/icons-material/Close';

import {
  IoMdArrowRoundBack as ArrowRoundBack,
  IoMdClose as Close,
  IoMdEyeOff,
  IoMdPricetag,
} from 'react-icons/io';

import {
  IoCheckmark as CheckmarkIcon,
  IoChevronBack as CheveronLeftIcon,
  IoAdd as AddIcon,
} from 'react-icons/io5';

import {
  MdArrowForward as ArrowForward,
  MdExpandMore,
  MdMailOutline as MailOutline,
  MdPhone as Phone,
  MdKeyboardArrowRight,
} from 'react-icons/md';

import { RiBankLine as MakeACH, RiPercentLine } from 'react-icons/ri';

import { TbLink, TbFilterFilled, TbFilterX } from 'react-icons/tb';

import { CiCircleCheck, CiCircleRemove } from 'react-icons/ci';
import { FaFilePdf } from 'react-icons/fa6';

export const ICONS = {
  AiFillEye,
  ArrowForward,
  ArrowRoundBack,
  BiCheckCircle,
  BiInfoCircle,
  BiRadioCircle,
  BiCircle,
  CheckmarkIcon,
  CheckShield,
  CheveronLeftIcon,
  CheveronRightIcon,
  CircleCheckIcon,
  Close,
  Download,
  EditIcon,
  ErrorIcon,
  FiArrowRightCircle,
  ImCheckboxChecked,
  Info,
  InformationCircleIcon,
  InProgressIcon,
  IoMdEyeOff,
  LockIcon,
  LoginCards,
  Logout,
  MailOutline,
  MakeACH,
  MakeWire,
  MdExpandMore,
  MdKeyboardArrowRight,
  OctagonIcon,
  OutlineArrowNarrowRight,
  OutlineCalendar,
  OutlineCash,
  OutlineCheckCircle,
  OutlineCircleIcon,
  OutlineLibrary,
  OutlineOfficeBuilding,
  OutlineUser,
  PayboticLogoSimpleIcon,
  Pencil,
  Phone,
  RectangleIcon,
  RefreshIcon,
  RiPercentLine,
  ShieldCheck,
  TbLink,
  Trash,
  WriteCheckIcon,
  VerifyEmail,
  AlertIcon,
  DownloadOutlined,
  HamburgerIcon,
  SlLink,
  FaDownload,
  BiSolidFilterAlt,
  BiFilterAlt,
  TbFilterFilled,
  TbFilterX,
  CloseMUI,
  VscDebugConsole,
  CiCircleCheck,
  CiCircleRemove,
  PiBuildingsFill,
  FaFilePdf,
  BsCloudUpload,
  FaApplePay,
  FaGooglePay,
  AddIcon,
  IoMdPricetag,
} as const;
