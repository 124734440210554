import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import type { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { forwardRef } from 'react';
import { useTheme, type SxProps, useMediaQuery } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import _isEmpty from 'lodash/isEmpty';
import { CSS_VARS } from '@/constants/css-vars';

import { selectDisplayStyles, StyledHelperTextContentWrapper } from './styles';

import type { DropdownProps, OptionValue } from './types';

import { getInputStatus, isInvalid, statusColor, statusIcon } from './utils';

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MENU_PROPS: Partial<MenuProps> = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SELECT_DISPLAY_PROPS = {
  style: selectDisplayStyles,
};

const InnerInputDropdown = <
  OV extends string,
  FieldErrors extends Record<string, unknown> = Record<string, unknown>,
>(
  props: DropdownProps<OV, FieldErrors> & {
    errorFlag?: boolean;
    sx?: SxProps;
    renderOption?: (option: any) => JSX.Element;
    renderValue?: (selected: string) => string;
  },
  ref: React.ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  const {
    disabled,
    endAdornment,
    fieldErrors,
    helperText,
    hideLabel = false,
    label,
    name,
    noNoneOption = false,
    options,
    placeholder,
    required = false,
    showHelperText = false,
    noHelperIcon = false,
    startAdornment,
    width,
    sx,
    errorFlag = false,
    customMenuHeight,
    disabledSelectDefault = false,
    renderOption,
    renderValue: renderValueProp,
    ...restOfProps
  } = props;

  const describedBy = `${name}-helper-text`;

  const labelBy = `${name}-label`;

  const isInputInvalid = isInvalid(fieldErrors);

  const status = getInputStatus(isInputInvalid);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const widthToRender = isMobile ? '100%' : width;

  const renderValue = (value: OptionValue<OV>): any => {
    if (_isEmpty(value) && placeholder) {
      return <Typography color="gray">{placeholder}</Typography>;
    }

    if (Array.isArray(value)) {
      const selectedOptions = value.map((option) => {
        // eslint-disable-next-line
        const selectedOption: any = options.find(
          // eslint-disable-next-line
          (opt: any) => opt?.value === option,
        );

        return selectedOption?.label ?? option;
      });

      const result = selectedOptions.join(', ');

      if (typeof renderValueProp === 'function') {
        return renderValueProp(result);
      }

      return result;
    }

    // eslint-disable-next-line
    const selectedMapping: any = options.find(
      // eslint-disable-next-line
      (opt: any) => opt?.value === value,
    );

    return selectedMapping?.label ?? value;
  };

  return (
    <div style={{ width: widthToRender ?? '20rem' }}>
      {!hideLabel && (
        <InputLabel
          id={labelBy}
          required={required}
          sx={{
            color: required ? `var(${CSS_VARS.Colors.Error.Dark})` : 'inherit',
          }}
        >
          <Typography display="inline" fontWeight={700} variant="body2">
            {label}
          </Typography>
        </InputLabel>
      )}

      <Select<OptionValue<OV>>
        aria-describedby={describedBy}
        disabled={disabled}
        displayEmpty
        endAdornment={endAdornment}
        error={isInputInvalid ?? errorFlag}
        fullWidth
        id={name}
        inputRef={ref}
        labelId={labelBy}
        MenuProps={customMenuHeight ?? MENU_PROPS}
        name={name}
        required={required}
        SelectDisplayProps={SELECT_DISPLAY_PROPS}
        startAdornment={startAdornment}
        renderValue={renderValue}
        {...restOfProps}
      >
        {noNoneOption ? null : (
          <MenuItem
            sx={{ color: `var(${CSS_VARS.Colors.BlackBlue})`, opacity: 0.4 }}
            value=""
            disabled={disabledSelectDefault}
          >
            {placeholder ?? 'None'}
          </MenuItem>
        )}

        {options.map((option, index) => {
          if (typeof option === 'object' && 'id' in option) {
            return (
              <MenuItem
                key={option.id}
                value={option.value}
                disabled={option?.disabled}
              >
                {props.multiple && (
                  <Checkbox
                    // eslint-disable-next-line
                    checked={props.value?.includes(option.value as any)}
                  />
                )}

                {renderOption?.(option) || option.label}
              </MenuItem>
            );
          }

          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>

      {showHelperText && (
        <FormHelperText id={describedBy}>
          {helperText == null ? (
            ' '
          ) : !noHelperIcon ? (
            <StyledHelperTextContentWrapper
              style={{ color: `var(${statusColor[status]})` }}
            >
              {statusIcon[status]}&nbsp;{helperText}
            </StyledHelperTextContentWrapper>
          ) : (
            <>{helperText}</>
          )}
        </FormHelperText>
      )}
    </div>
  );
};

const InputDropdown = forwardRef(InnerInputDropdown) as <
  OV extends string,
  FieldErrors extends Record<string, unknown> = Record<string, unknown>,
>(
  props: DropdownProps<OV, FieldErrors> & {
    errorFlag?: boolean;
    sx?: SxProps;
    renderOption?: (option: any) => JSX.Element;
    renderValue?: (selected: any) => string;
  },
  ref: React.ForwardedRef<HTMLDivElement>,
) => JSX.Element;

export default InputDropdown;
