import type {
  ItemDto,
  SectionDto,
} from '@/api/hooks/useUpdateFacilityInformation/types';
import { getRadioOptionList } from '@/utils/get-radio-option-list';
import {
  BUSINESS_DETAILS_SECTION_ID,
  BUSINESS_DETAILS_SECTION_TITLE,
} from '@pages/Facilities/Sections/Details/types';
import type { UseFormSetValue } from 'react-hook-form';
import type { AddressesFormSchema, FacilityWithMailingAddress } from './schema';

export const OWN_OR_LEASING_OPTIONS = ['own', 'lease'] as const;

export const ownOrLeasingOptions = getRadioOptionList({
  labels: ['Own', 'Lease'],
  values: OWN_OR_LEASING_OPTIONS,
});

export const ADDRESS_PAGE_ID = 'facility-address-page';

export function getNormalizedData(
  businessData: AddressesFormSchema,
  isMailingAddress: boolean,
): SectionDto {
  let items: ItemDto[] = [];
  const {
    'postal-code': postalCode,
    'street-address': streetAddress,
    'unit-number': unitNumber,
    city,
    country,
    state,
  } = businessData;

  items = items.concat([
    {
      id: 'postal-code',
      type: 'QUESTION',
      question: 'Postal Code',
      answer: postalCode,
    },
    {
      id: 'street-address',
      type: 'QUESTION',
      question: 'Street Address',
      answer: streetAddress,
    },
    { id: 'city', type: 'QUESTION', question: 'City', answer: city },
    { id: 'country', type: 'QUESTION', question: 'Country', answer: country },
    { id: 'state', type: 'QUESTION', question: 'State', answer: state ?? '' },
  ]);

  if (unitNumber !== null && unitNumber.length > 0) {
    items.push({
      id: 'unit-number',
      type: 'QUESTION',
      question: 'Apartment / Suite #',
      answer: unitNumber,
    });
  }

  const {
    'mailing-city': mailingCity,
    'mailing-country': mailingCountry,
    'mailing-postal-code': mailingPostalCode,
    'mailing-state': mailingState,
    'mailing-street-address': mailingStreetAddress,
    'mailing-unit-number': mailingUnitNumber,
  } = businessData as FacilityWithMailingAddress;

  const mailingAddress = isMailingAddress
    ? {
        mailingCity,
        mailingCountry,
        mailingPostalCode,
        mailingState,
        mailingStreetAddress,
        mailingUnitNumber,
      }
    : {
        mailingCity: city,
        mailingCountry: country,
        mailingPostalCode: postalCode,
        mailingState: state,
        mailingStreetAddress: streetAddress,
        mailingUnitNumber: unitNumber,
      };

  items = items.concat([
    {
      id: 'mailing-postal-code',
      type: 'QUESTION',
      question: 'Mailing Postal Code',
      answer: mailingAddress.mailingPostalCode,
    },
    {
      id: 'mailing-street-address',
      type: 'QUESTION',
      question: 'Mailing Street Address',
      answer: mailingAddress.mailingStreetAddress,
    },
    {
      id: 'mailing-city',
      type: 'QUESTION',
      question: 'Mailing City',
      answer: mailingAddress.mailingCity,
    },
    {
      id: 'mailing-country',
      type: 'QUESTION',
      question: 'Mailing Country',
      answer: mailingAddress.mailingCountry,
    },
  ]);

  if (
    mailingAddress.mailingState !== null &&
    mailingAddress.mailingState.length > 0
  ) {
    items.push({
      id: 'mailing-state',
      type: 'QUESTION',
      question: 'Mailing State',
      answer: mailingAddress.mailingState,
    });
  }

  if (
    mailingAddress.mailingUnitNumber !== null &&
    mailingAddress.mailingUnitNumber.length > 0
  ) {
    items.push({
      id: 'mailing-unit-number',
      type: 'QUESTION',
      question: 'Mailing Apartment / Suite #',
      answer: mailingAddress.mailingUnitNumber,
    });
  }

  return {
    id: BUSINESS_DETAILS_SECTION_ID,
    title: BUSINESS_DETAILS_SECTION_TITLE,
    page: {
      id: ADDRESS_PAGE_ID,
      title: 'Business Detail Section - Address',
      items: items.filter((item) => !!item.answer),
    },
  };
}

export function setFacilityAddressValues(
  detailType: SectionDto,
  setValues: UseFormSetValue<AddressesFormSchema>,
): void {
  detailType.page.items.forEach((item) => {
    setValues(item.id as keyof AddressesFormSchema, item.answer);
  });
}
