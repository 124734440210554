import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useCallback, useEffect, useMemo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import BaseLayout from '@/components/Layouts/BaseLayout';

import { useOnboardingProgress } from '@/store/useOnboardingProgress';
import {
  useOnboardingSections,
  type TabSection,
} from '@/utils/hooks/useOnboardingSections';
import { navigationStep } from '@/features/ui/navigation';
import { useFacilitySectionData } from '@/pages/Facilities/hooks/useFacilitySectionData';

import useGetFacilities from '@/api/hooks/useGetFacilities';
import LoadingComponent from '@/components/UI/LoadingComponent';
import StepNavigation from '@/components/Sidebar/SideBarNavigation/components/StepNavigation';
import { useOnboardingLocationSections } from '@/utils/hooks/useOnboardingLocationSections';

const FacilitiesLayout = (): JSX.Element => {
  const { onboardingSteps, setCurrentFacilityPath } = useOnboardingProgress();

  const {
    businessId,
    business,
    sections,
    getSectionStatus,
    checkSectionCompleted,
  } = useOnboardingSections();
  const path = useLocation();

  const { description, title, setStateIssuedRequired } =
    useFacilitySectionData();

  const normalizedFacilityMenuItems = useOnboardingLocationSections(business);

  useGetFacilities(businessId ?? '', false, {
    enabled: businessId != null,
    onSuccess(data) {
      setStateIssuedRequired(data[0]?.stateIssuedRequired ?? false);
    },
  });

  useEffect(() => {
    setCurrentFacilityPath(path.pathname + path.search);
  }, [path.pathname, path.search, setCurrentFacilityPath]);

  const handleDisabledNavigation = (navigationPath: string): boolean => {
    const sectionsToCheck: Record<string, string[]> = {
      [navigationStep.BusinessDetails]: [],
      [navigationStep.BusinessOperatorDetails]: ['business-details'],
      [navigationStep.BusinessOwnership]: [
        'business-details',
        'business-operator',
      ],
      [navigationStep.Facilities]: [
        'business-details',
        'business-operator',
        'ownership',
      ],
      [navigationStep.BusinessDocuments]: [
        'business-details',
        'business-operator',
        'ownership',
        'facilities',
      ],
      [navigationStep.ReviewAndSign]: [
        'business-details',
        'business-operator',
        'ownership',
        'facilities',
        'business-documents',
      ],
    };

    const requiredSections = sectionsToCheck[navigationPath] || [];

    for (const section of requiredSections) {
      if (!checkSectionCompleted(section as TabSection)) {
        return true;
      }
    }

    return false;
  };

  const normalizedNavigationItemList = useMemo(() => {
    return Object.values(onboardingSteps).map((navigationItem) => {
      const isDisabled = handleDisabledNavigation(navigationItem.id);
      const progressStatus = getSectionStatus(navigationItem.id as TabSection);

      return {
        ...navigationItem,
        checkBox: true,
        isDisabled,
        progressStatus,
      };
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sections)]);

  const renderNavList = useCallback(
    (toggleMobileSidebarOpen?: () => void) => {
      return (
        <StepNavigation
          list={
            business?.finishedOnboard
              ? normalizedFacilityMenuItems
              : normalizedNavigationItemList
          }
          toggleMobileSidebarOpen={toggleMobileSidebarOpen}
        />
      );
    },
    [
      business?.finishedOnboard,
      normalizedFacilityMenuItems,
      normalizedNavigationItemList,
    ],
  );

  return business == null ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '50vh',
      }}
    >
      <LoadingComponent />
    </Box>
  ) : (
    <BaseLayout
      centerContent
      hasHeader
      hasSidebar
      renderNavList={renderNavList}
    >
      <Box sx={{ py: { xs: 4, md: 6 }, px: { xs: 2, md: 6 } }}>
        {/* Removed the title information from here */}

        {typeof title === 'string' && (
          <Typography gutterBottom variant="h3" fontWeight={700}>
            {title}
          </Typography>
        )}

        <Typography fontWeight={500} marginBottom={4} variant="body1">
          {description}
        </Typography>

        <Outlet />
      </Box>
    </BaseLayout>
  );
};

export default FacilitiesLayout;
