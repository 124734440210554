import logoutUserFetcher from '@/api/hooks/userAuthUser/logoutUser';
import { APP_EVENTS, StorageKeys } from '@/constants/misc';
import { Router } from '@/constants/router';
import { useMultipleProfiles } from '@/store/useMultipleProfiles';
import { useAuth0 } from '@auth0/auth0-react';

interface IUseLogout {
  logout: (url?: string) => void;
}

export const clearLocalStorage = () => {
  localStorage.removeItem(StorageKeys.Token);
  localStorage.removeItem('facilities-storage');
  localStorage.removeItem('create-new-facility');
  localStorage.removeItem('selected-facility');
  localStorage.removeItem('onboarding-steps');
  localStorage.removeItem('user-profiles');
  localStorage.removeItem('multi-business');
  localStorage.removeItem('_expiredTime');
  localStorage.removeItem('system-roles');
};

const useLogout = (): IUseLogout => {
  const { logout: logoutAuth0 } = useAuth0();

  const logout = (url?: string): void => {
    void logoutUserFetcher().finally(() => {
      clearLocalStorage();

      logoutAuth0({
        logoutParams: {
          returnTo: `${window.location.origin}${url ?? ''}`,
          federated: true,
        },
      }).catch(() => {
        localStorage.clear();
        window.location.href = '/';
      });
    });
  };

  return { logout };
};

const useLogoutProfile = () => {
  const groupProfile = useMultipleProfiles((state) => state.groupProfile);
  const setUserProfile = useMultipleProfiles((state) => state.setUserProfile);

  const logoutProfile = () => {
    localStorage.removeItem('facilities-storage');
    localStorage.removeItem('create-new-facility');
    localStorage.removeItem('selected-facility');
    localStorage.removeItem('onboarding-steps');
    setUserProfile(null);

    if (groupProfile?.isSuperAdmin) {
      window.open(Router.MultiBusinesses.ManageBusinesses, '_self');
    } else {
      window.open(Router.MultiBusinesses.BusinessLogin, '_self');
    }
  };

  return { logoutProfile };
};

const triggerLogout = () => {
  document.dispatchEvent(new Event(APP_EVENTS.LOGOUT));
};

export { useLogoutProfile, triggerLogout };

export default useLogout;
